import Image from "next/image"
import NextLink from "next/link"

import {Box, Link, Text} from "@chakra-ui/react"

import LogoPic from "../../../public/Logo-IPCO.png"
import styles from "../../../styles/components/NavBar.module.css"
import {MyButton} from "./MyButton"


export function NavBar() {

    return (<nav>
        <div className={styles.NavBarContainer}>
            <div className={styles.Content}>
                <div className={styles.Logo}>
                    <NextLink href={"/"} passHref>
                        <Link>
                            <Box>
                                <Image
                                    src={LogoPic}
                                    width={115}
                                    height={115}
                                    placeholder="blur"
                                    blurDataURL={LogoPic}
                                    alt="Logo do Instituto Plinio Corrêa de Oliveira"
                                />
                            </Box>
                        </Link>
                    </NextLink>
                    <div className={styles.Name}>
                        <Box>
                                <span className={styles.FirstLine}>
                                    <span className={styles.FirstLetters}>I</span>nstituto
                                </span>
                        </Box>

                        <Box>
                            <Text marginTop="-14px">
                                <span className={styles.FirstLetters}>P</span>linio <span
                                className={styles.FirstLetters}>C</span>orrêa de <span
                                className={styles.FirstLetters}>O</span>liveira
                            </Text>
                        </Box>
                    </div>
                </div>
                <div className={styles.Menu}>
                    <MyButton description="Home" link="/"/>
                    <MyButton description="Sobre" link="/#sobre"/>
                    <MyButton description="Nossas Conquistas" link="/#nossas-conquistas"/>
                    <MyButton description="Fale Conosco" link="/#fale-conosco"/>
                </div>
            </div>
        </div>
    </nav>)
}
