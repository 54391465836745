import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import NextLink from "next/link";

export function MyButton({ description, link }) {
    return <LinkBox
        as={"button"}
        // @ts-ignore
        variant="outline"
        color={'gray.600'}
        bgColor={'blueBrand.50'}
        ml="0.8rem"
        textTransform="uppercase"
        h="39px"
        borderRadius="sm"
        py="5px"
        px="10px"
        fontSize="md"
        fontWeight="400"
        fontFamily="Roboto"
        _focus={{
            filter: 'brightness(90%)',
        }}
        _hover={{
            filter: 'brightness(90%)',
        }}
        transition={"filter 0.3s"}
    >
        <NextLink href={link} passHref>
            <LinkOverlay textDecoration="none">
                {description}
            </LinkOverlay>
        </NextLink>
    </LinkBox>
}
