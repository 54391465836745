import { Flex, HStack, Link, StackDivider, Text } from "@chakra-ui/react";
import NextLink from "next/link";

export const preHeaderData = [
  {
    description: "Portal do IPCO",
    link: "https://ipco.org.br"
  },
  {
    description: "Plinio Corrêa de Oliveira",
    link: "https://pliniocorreadeoliveira.info/novidades.asp"
  },
  {
    description: "IPCO em Ação",
    link: "/"
  },
  // {
  //   description: "EAD IPCO",
  //   link: ""
  // },
]

export function PreHeader() {
  return <Flex
    flexDir={"row"}
    justifyContent="space-between"
    py={1}
    h={7}
    fontSize={"sm"}
    maxW="5xl"
    mx="auto"
    px={2}
    sx={{
      '@media only screen and (max-width: 768px)': {
        display: "none"
      }
    }}
  >
    <HStack
      divider={<StackDivider borderColor='gray.700' />}
      spacing={4}
      align='stretch'
    >
      {
        preHeaderData.map((item) => (
          <NextLink href={item.link} key={item.link} passHref>
            <Link
              _hover={{ textDecoration: "none" }}
              isExternal={item.link === '/' ? false : true}
            >
              <Text as="span" fontSize={"lg"}>{item.description}</Text>
            </Link>
          </NextLink>
        ))
      }
    </HStack>
    {/* <NextLink href="/login" passHref>
      <Link
        _hover={{ textDecoration: "none" }}
      >
        <Flex flexDir={"row"} justifyContent="space-between" alignItems="center">
          <Icon as={BsFillPersonFill} />
          <Text pl={2} fontSize="sm">Acesse sua conta</Text>
        </Flex>
      </Link>
    </NextLink> */}
  </Flex>
}