import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import '../../styles/globals.css';
import { NavBar } from "../components/NavBar";
import { PreHeader } from "../components/NavBar/PreHeader";
import { GTM_ID, pageview } from '../services/gtm';

export default function App({ Component, pageProps }) {
    const theme = extendTheme({
        colors: {
            yellowBrand: {
                50: "#FCF7E9",
                100: "#F9EED4",
                200: "#F4DDA8",
                300: "#EECC7D",
                400: "#E9BB51",
                500: "#E3AA26",
                600: "#B6881E",
                700: "#886617",
                800: "#5B440F",
                900: "#2D2208"
            },
            blue: {
                "secondary": "#b2d8ee"
            },
            blueBrand: {
                50: "#E6ECEF",
                100: "#CDD8DF",
                200: "#9BB1BF",
                300: "#698A9E",
                400: "#37637E",
                500: "#053C5E",
                600: "#04304B",
                700: "#032438",
                800: "#021826",
                900: "#010C13"
            },
            redBrand: {
                50: "#F5E8E8",
                100: "#EBD1D2",
                200: "#D6A3A4",
                300: "#C27677",
                400: "#AD4849",
                500: "#991A1C",
                600: "#7A1516",
                700: "#5C1011",
                800: "#3D0A0B",
                900: "#1F0506"
            },
            gray: {
                "base": "#E5E5E5"
            },
        }, fonts: {
            heading: "Neuton, serif", button: "Roboto", body: "Rasa",
        },
    })

    const router = useRouter()
    useEffect(() => {
        router.events.on('routeChangeComplete', pageview)
        return () => {
            router.events.off('routeChangeComplete', pageview)
        }
    }, [router.events])
    return (<>
        <Script
            id={"google-tag-manager"}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${GTM_ID}');
                            `
            }}
        />
        <Script
            id={"goadopt"}
            src="//tag.goadopt.io/injector.js?website_code=0afcffae-1bcb-4cbb-9c69-4d5c7b11a664"
            class="adopt-injector" />
        <ChakraProvider theme={theme}>
            <PreHeader />
            <NavBar />
            <Component {...pageProps} />
        </ChakraProvider>
    </>)
}
